import React from 'react';
export default (images, pageAction) => ({
  title: 'cửa hàng nhà hàng',
  navbarMenuItems: [
    {label: 'Bắt đầu bây giờ'},
    {label: 'Chọn ngôn ngữ của bạn:'}
  ],
  banner: {
    subTitle: 'Đơn giản, cá nhân & miễn phí hoa hồng',
    title: 'Cửa hàng nhà hàng trực tuyến của riêng bạn',
    description: 'Cung cấp trải nghiệm đặt hàng trực tuyến trực quan. Tiếp cận nhiều khách hàng hơn và bán được nhiều hàng hơn.',
    actionLabel: 'Bắt đầu bây giờ',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'Khách hàng của chúng tôi hài lòng',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto đã thay đổi cuộc chơi cho doanh nghiệp của tôi. Hệ thống này cực kỳ dễ sử dụng và cho phép khách hàng đặt hàng trực tiếp từ trang web của tôi chỉ với vài cú nhấp chuột. Quá trình này cực kỳ suôn sẻ và nhanh chóng, và tôi rất vui khi nói rằng nó đã thúc đẩy đáng kể doanh số bán hàng của tôi. Tôi đặc biệt giới thiệu Easyresto cho tất cả các chủ nhà hàng đang tìm kiếm một cách dễ dàng, hiệu quả để nhận đơn đặt hàng trực tuyến.",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Truy cập trang web',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "Chúng tôi đã sử dụng easyresto cho hệ thống đặt hàng trực tuyến của nhà hàng chúng tôi trong vài tháng nay và hoàn toàn hài lòng với kết quả đạt được! Nó rất dễ sử dụng và khách hàng của chúng tôi yêu thích sự tiện lợi. Nó đã giúp tăng doanh số bán hàng của chúng tôi và khách hàng của chúng tôi rất hài lòng với quy trình này. Thực sự khuyên dùng easyresto cho bất kỳ nhà hàng nào muốn cung cấp cho khách hàng của họ trải nghiệm đặt hàng trực tuyến tốt nhất!",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Truy cập trang web',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "Tại Pizz & Via, chúng tôi không thể hài lòng hơn với easyresto. Quá trình thiết lập diễn ra nhanh chóng và dễ dàng, mang đến cho khách hàng của chúng tôi trải nghiệm đặt hàng trực tuyến cao cấp. Đội ngũ dịch vụ khách hàng của họ luôn sẵn sàng trợ giúp và hướng dẫn của họ là hàng đầu. Chúng tôi thực sự khuyên dùng easyresto cho bất kỳ nhà hàng nào đang tìm kiếm một hệ thống đặt hàng trực tuyến hiệu quả và đáng tin cậy!",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Truy cập trang web',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "484 / 5,000\n" +
          "Translation results\n" +
          "Translation result\n" +
          "Easyresto là một hệ thống đặt hàng trực tuyến tuyệt vời cho nhà hàng Châu Phi của chúng tôi, Blaues Zebra. Chúng tôi đã sử dụng nó được vài tháng rồi và quy trình hợp lý của nó giúp khách hàng dễ dàng gọi món ngon của chúng tôi. Giao diện người dùng rất trực quan, giúp khách hàng dễ dàng tìm thấy những gì họ đang tìm kiếm và đặt hàng nhanh chóng. Đội ngũ dịch vụ khách hàng luôn sẵn sàng bất cứ khi nào chúng tôi có câu hỏi. Chúng tôi đánh giá cao hệ thống đặt hàng trực tuyến này!",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Truy cập trang web',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'Bạn tiết kiệm được bao nhiêu với Easyresto?',
    provision: 'Hoa hồng cổng thông tin',
    ordersPerMonth: 'đơn đặt hàng mỗi tháng',
    orderValue: 'giá trị đơn hàng',
    result: 'Kết quả:',
    perMonth: 'Mỗi tháng'
  },
  features: [
    {
      slogan: 'Khả năng hiển thị cao hơn 100%',
      title: 'Xếp hạng cao hơn trên google so với các nhà cung cấp khác',
      description: 'Hệ thống của chúng tôi được thiết kế để đáp ứng các thông lệ tốt nhất về tiêu chuẩn tối ưu hóa công cụ tìm kiếm ngày nay. Chúng tôi sử dụng công nghệ mới nhất để đảm bảo rằng trang web của bạn luôn được cập nhật với các chiến lược SEO mới nhất. Điều này giúp đảm bảo rằng trang web của bạn luôn có thứ hạng cao hơn so với các nhà cung cấp khác. Chúng tôi cũng đảm bảo luôn cập nhật các thuật toán luôn thay đổi của công cụ tìm kiếm.',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'Sự hài lòng của khách hàng giảm 16% do chậm trễ một giây.',
      title: 'Tải với tốc độ cực nhanh trên thiết bị di động',
      description: 'Hệ thống của chúng tôi được thiết kế để cung cấp thời gian tải trung bình nhanh hơn tới 30% so với các nhà cung cấp khác. Các trang web tải nhanh hơn có thể mang lại nhiều lợi ích, chẳng hạn như cải thiện sự hài lòng của khách hàng, tăng chuyển đổi và xếp hạng công cụ tìm kiếm tốt hơn.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: '88% người tiêu dùng ít có khả năng quay lại trang web có trải nghiệm người dùng kém.',
      title: 'Trải nghiệm người dùng trực quan',
      description: 'easyresto không chỉ nhanh hơn các hệ thống cửa hàng trực tuyến khác mà còn cung cấp giao diện trực quan và thân thiện với người dùng giúp đặt hàng dễ dàng và hiệu quả. Chỉ với vài cú click chuột, khách hàng có thể đặt món hàng mình cần một cách nhanh chóng và dễ dàng. Không giống như một số nhà cung cấp khác, họ không phải trải qua các menu và quy trình đặt hàng phức tạp.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Đặt hàng một cách dễ dàng - Easyresto!',
    description: 'Đừng bỏ lỡ cơ hội đưa nhà hàng của bạn lên một tầm cao mới - đăng ký ngay bây giờ với Easyresto và bắt đầu tăng lợi nhuận của bạn!',
    action: {
      label: 'Bắt đầu bây giờ',
    },
    features: [
      'Hỗ trợ Whatsapp & Email',
      'giới thiệu miễn phí',
      'Bắt đầu sau chưa đầy 48 giờ',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Truy cập từ mọi nơi, mọi lúc',
      title: 'Quản lý webshop của bạn trên điện thoại của bạn. đáp ứng phụ trợ',
      description: 'Nếu bạn là chủ nhà hàng, bạn sẽ biết tầm quan trọng của việc cập nhật công nghệ mới nhất. Với EasyResto, bạn có thể quản lý webshop của mình từ điện thoại mọi lúc, mọi nơi. Chương trình phụ trợ đáp ứng nhanh của chúng tôi cho phép bạn dễ dàng quản lý cửa hàng trực tuyến của mình bằng một vài lần nhấn đơn giản.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Thẻ tín dụng/thẻ ghi nợ, Klarna, Sofort, Paypal, Giropay và nhiều hơn nữa',
      title: 'Nhiều phương thức thanh toán',
      description: 'Hệ thống cửa hàng trực tuyến Easyresto mang đến cho người dùng sự thuận tiện trong việc lựa chọn phương thức thanh toán ưa thích khi mua hàng. Chúng tôi cung cấp cho người dùng các tùy chọn thẻ tín dụng, PayPal, Google Pay, Apple Pay và các phương thức thanh toán khác. Tất cả các phương thức thanh toán đều an toàn và bảo mật, đồng thời tất cả các giao dịch đều được mã hóa.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Xuất khẩu bằng một cú nhấp chuột cho các đơn đặt hàng của bạn',
      title: 'Báo cáo cho cố vấn thuế',
      description: 'Chúng tôi biết tầm quan trọng của việc có được thông tin kế toán hóa đơn chính xác và đáng tin cậy đối với các cố vấn thuế, đó là lý do tại sao chúng tôi không gặp khó khăn trong việc theo dõi hồ sơ hóa đơn bằng hệ thống dựa trên web toàn diện và thân thiện với người dùng của chúng tôi. Hệ thống của chúng tôi được thiết kế để hợp lý hóa các quy trình và giúp cố vấn thuế quản lý tài chính của khách hàng dễ dàng hơn.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Yêu cầu',
    'emailRequired': '* Vui lòng cung cấp địa chỉ email chính xác.',
    'thankYou': 'CẢM ƠN',
    'thankYouMessage': 'Chúng tôi đã nhận được yêu cầu của bạn và sẽ liên hệ với bạn trong thời gian sớm nhất.',
    'error': 'Lỗi',
    'errorMessage': 'Vui lòng thử lại một lần nữa.',
    'name': 'Tên*',
    'restaurantName': 'Tên nhà hàng*',
    'mobile': 'Di động*',
    'email': 'E-mail*',
    'zipCode': 'Mã Bưu Chính*',
    'plan': 'Kế hoạch của bạn*',
    'monthly': 'hàng tháng - 69,00 €',
    'monthlyDescription': '* hủy bỏ bất cứ lúc nào',
    'yearly': 'hàng năm - 59,00 €',
    'yearlyDescription': '* Hợp đồng 12 tháng',
    'oneTimeFee': 'Phí một lần [thiết bị đặt hàng + tài liệu tiếp thị]',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* chỉ thanh toán một lần',
    'oneTimeFeeInInstallment': '25,00 € / tháng',
    'oneTimeFeeInInstallmentDescription': '* trả góp 12 tháng đầu',
    'agbConfirm': <>tôi đồng ý <a href="/agb" target="_blank">Điều khoản & Điều kiện và Chính sách bảo mật</a>.</>,
    'submit': 'Liên hệ chúng tôi',
    'ok': 'Được',
  },
});