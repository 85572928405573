import Sticky from "react-stickynode";
import React from "react";

import Layout from 'containers/layout';
import Seo from 'components/seo';
import {ResetCSS} from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasMinimal2/saasMinimal2.style';
import BaseNavbar from 'containers/SaasMinimal2/BaseNavbar';
import Footer from 'containers/SaasMinimal2/Footer';


export default ({children, activeItemId, seoProps, overrideMenuItems, hideMobileMenu}) => {
  return <Layout>
    <Seo {...seoProps}/>
    <ResetCSS/>
    <GlobalStyle/>
    <ContentWrapper>
      <Sticky top={0} innerZ={10} activeClass="is-sticky">
        <BaseNavbar activeItemId={activeItemId} overrideMenuItems={overrideMenuItems} hideMobileMenu={hideMobileMenu}/>
      </Sticky>
    </ContentWrapper>
    {children}
    <Footer/>
  </Layout>
}