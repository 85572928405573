import React from 'react';
export default (images, pageAction) => ({
  title: 'Restaurante Tienda',
  navbarMenuItems: [
    {label: 'Empezar ahora'},
    {label: 'Elige tu idioma:'}
  ],
  banner: {
    subTitle: 'Sencilla, individual y sin comisiones.',
    title: 'Tu propia tienda de restaurante online',
    description: 'Ofrezca una experiencia intuitiva de pedidos en línea. Llegue a más clientes y haga más ventas.',
    actionLabel: 'Empezar ahora',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'Nuestras clientas estan satisfechas',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto ha cambiado las reglas del juego de mi negocio. El sistema es increíblemente fácil de usar y permite a los clientes hacer pedidos directamente desde mi sitio web con unos pocos clics. El proceso es extremadamente fluido y rápido, y estoy encantado de decir que ha aumentado significativamente mis ventas. Recomiendo encarecidamente Easyresto a todos los propietarios de restaurantes que busquen una forma fácil y eficaz de tomar pedidos en línea",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Visitar el sitio web',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "Llevamos varios meses utilizando easyresto para el sistema de pedidos en línea de nuestro restaurante y estamos encantados con los resultados. Es muy fácil de usar y a nuestros clientes les encanta su comodidad. Ha ayudado a aumentar nuestras ventas y nuestros clientes están muy contentos con el proceso. Recomiendo encarecidamente easyresto a cualquier restaurante que quiera ofrecer a sus clientes la mejor experiencia de pedidos en línea",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Visitar el sitio web',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "En Pizz & Via, no podríamos estar más contentos con easyresto. La configuración fue rápida y sencilla, y proporcionó a nuestros clientes una experiencia de pedido en línea de primera calidad. Su equipo de atención al cliente está siempre dispuesto a ayudar y su orientación es de primera categoría. Recomendamos easyresto a cualquier restaurante que busque un sistema de pedidos online eficaz y fiable",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Visitar el sitio web',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto es un fantástico sistema de pedidos en línea para nuestro restaurante africano, Blaues Zebra. Llevamos unos meses utilizándolo y su proceso simplificado hace que a los clientes les resulte muy fácil pedir nuestros deliciosos platos. La interfaz de usuario es muy intuitiva, lo que facilita que los clientes encuentren lo que buscan y hagan sus pedidos rápidamente. El equipo de atención al cliente está siempre disponible cuando tenemos alguna pregunta. Recomendamos encarecidamente este sistema de pedidos en línea",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Visitar el sitio web',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: '¿Cuánto ahorras con Easyresto?',
    provision: 'Comisión del portal',
    ordersPerMonth: 'pedidos al mes',
    orderValue: 'valor del pedido',
    result: 'Resultado:',
    perMonth: 'Al mes'
  },
  features: [
    {
      slogan: '100% más de visibilidad',
      title: 'Mejor posición en Google que otros proveedores',
      description: 'Nuestro sistema está diseñado para cumplir con las mejores prácticas de los estándares actuales de optimización de motores de búsqueda. Utilizamos la última tecnología para asegurarnos de que su sitio web esté siempre actualizado con las estrategias SEO más actuales. Esto ayuda a asegurar que su sitio web esté siempre mejor posicionado que otros proveedores. También nos aseguramos de mantenernos al día con los siempre cambiantes algoritmos de los motores de búsqueda',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'La satisfacción del cliente cae un 16% por un retraso de un segundo.',
      title: 'Se carga a la velocidad del rayo en el móvil',
      description: 'Nuestro sistema está diseñado para proporcionar tiempos de carga hasta un 30% más rápidos de media en comparación con otros proveedores. Los sitios web que se cargan más rápido pueden reportar muchas ventajas, como una mayor satisfacción del cliente, un aumento de las conversiones y un mejor posicionamiento en los motores de búsqueda.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'El 88% de los consumidores son menos propensos a volver a un sitio con una mala experiencia de usuario.',
      title: 'Experiencia de usuario intuitiva',
      description: 'easyresto no sólo es más rápido que otros sistemas de tienda web, sino que también ofrece una interfaz intuitiva y fácil de usar que hace que los pedidos sean sencillos y eficaces. Con unos pocos clics, los clientes pueden pedir los artículos que necesitan de forma rápida y sencilla. A diferencia de otros proveedores, no tienen que pasar por complicados menús y procesos de pedido',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Haz tu pedido fácilmente - Easyresto',
    description: 'No pierda la oportunidad de llevar su restaurante al siguiente nivel: regístrese ahora en Easyresto y empiece a aumentar sus beneficios',
    action: {
      label: 'Empezar ahora',
    },
    features: [
      'Asistencia por Whatsapp y correo electrónico',
      'Incorporación gratuita',
      'Inicio en menos de 48 horas',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Acceso desde cualquier lugar y en cualquier momento',
      title: 'Gestione su tienda virtual desde su teléfono. Backend responsivo',
      description: 'Si es propietario de un restaurante, sabe lo importante que es estar al día de las últimas tecnologías. Con EasyResto, puede gestionar su tienda virtual desde su teléfono, en cualquier momento y en cualquier lugar. Nuestro backend responsivo le permite gestionar fácilmente su tienda web con unos simples toques.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Tarjetas de crédito/débito, Klarna, Sofort, Paypal, Giropay y mucho más',
      title: 'Múltiples formas de pago',
      description: 'El sistema de tienda web Easyresto ofrece a los usuarios la comodidad de elegir su método de pago preferido al realizar compras. Proporcionamos a los usuarios las opciones de tarjetas de crédito, PayPal, Google Pay, Apple Pay y otros métodos de pago. Todos los métodos de pago son seguros y todas las transacciones están encriptadas.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Exporte sus pedidos con un solo clic',
      title: 'Informes para asesores fiscales',
      description: 'Sabemos lo importante que es para los asesores fiscales disponer de información fiable y precisa sobre la contabilidad de las facturas, por eso les facilitamos el seguimiento de los registros de facturas con nuestro completo y sencillo sistema basado en web. Nuestro sistema está diseñado para agilizar los procesos y facilitar a los asesores fiscales la gestión de las finanzas de sus clientes',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Obligatorio',
    'emailRequired': '* Indique una dirección de correo electrónico correcta.',
    'thankYou': 'GRACIAS',
    'thankYouMessage': 'Hemos recibido su solicitud y nos pondremos en contacto con usted lo antes posible.',
    'error': 'Error',
    'errorMessage': 'Por favor, inténtelo de nuevo una vez más.',
    'name': 'Nombre*',
    'restaurantName': 'Nombre del restaurante*',
    'mobile': 'Móvil*',
    'email': 'Correo electrónico*',
    'zipCode': 'Código postal*',
    'plan': 'Su plan*',
    'monthly': 'Mensualmente - 69,00 €',
    'monthlyDescription': '* cancelar en cualquier momento',
    'yearly': 'Anualmente - 59,00 €',
    'yearlyDescription': '* Contrato de 12 meses',
    'oneTimeFee': 'Tasa única [dispositivo de pedido + material de marketing].',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* sólo se paga una vez',
    'oneTimeFeeInInstallment': '25,00 € / mes',
    'oneTimeFeeInInstallmentDescription': '* pago a plazos durante los 12 primeros meses',
    'agbConfirm': <>Acepto <a href="/agb" target="_blank">Condiciones generales y política de privacidad</a>.</>,
    'submit': 'Póngase en contacto con nosotros',
    'ok': 'Ok',
  },
});