import React from 'react';
import {ThemeProvider} from "styled-components";
import {BaseProvider} from "baseui";
import {theme} from 'common/theme/saasMinimal2';
import {theme as baseWebTheme} from 'common/theme/saasMinimal2/baseWeb';
import Cookies from './cookies';


export default ({children}) => {
  return <ThemeProvider theme={theme}>
      <BaseProvider theme={baseWebTheme}>
        {children}
        <Cookies/>
      </BaseProvider>
    </ThemeProvider>;
}