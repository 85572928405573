import React from 'react';
export default (images, pageAction) => ({
  title: 'Restaurant-Webshop',
  navbarMenuItems: [
    {label: 'Jetzt anfangen'},
    {label: 'Wähle deine Sprache:'}
  ],
  banner: {
    subTitle: 'Einfach, individuell & provisionsfrei',
    title: 'Dein eigener Online-Restaurantshop',
    description: 'Bieten Sie ein intuitives Online-Bestellerlebnis. Erreichen Sie mehr Kunden und machen Sie mehr Umsatz.',
    actionLabel: 'Jetzt anfangen',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'Unsere Kunden sind zufrieden',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto hat mein Geschäft grundlegend verändert. Das System ist unglaublich einfach zu bedienen und ermöglicht es den Kunden, mit nur wenigen Klicks direkt von meiner Website aus zu bestellen. Der Prozess ist extrem reibungslos und schnell, und hat meinen Umsatz erheblich gesteigert . Ich kann Easyresto jedem Restaurantbesitzer empfehlen, die nach einer einfachen und effizienten Möglichkeit suchen, Bestellungen online entgegenzunehmen.",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Zur Webseite',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "Wir nutzen easyresto nun schon seit einigen Monaten und sind absolut begeistert von den Ergebnissen! Es ist so einfach zu bedienen und unsere Kunden lieben den Komfort. Es hat dazu beigetragen, unseren Umsatz zu steigern und unsere Kunden sind sehr zufrieden mit dem Bestellprozess. Ich kann easyresto jedem Restaurant empfehlen, das seinen Kunden das beste Online-Bestellsystem bieten möchte!",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Zur Webseite',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "Wir bei Pizz & Via könnten nicht zufriedener mit easyresto sein. Die Einrichtung war schnell und einfach und bietet unseren Kunden ein erstklassiges Online-Bestell-Erlebnis. Das Kundenservice-Team ist immer hilfsbereit und die Beratung ist erstklassig. Wir empfehlen easyresto jedem Restaurant, das nach einem effizienten und zuverlässigen Online-Bestellsystem sucht!",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Zur Webseite',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto ist ein fantastisches Online-Bestellsystem für unser afrikanisches Restaurant, das Blaue Zebra. Wir nutzen es jetzt seit ein paar Monaten und der optimierte Prozess macht es den Kunden sehr leicht, unsere köstlichen Gerichte zu bestellen. Die Benutzeroberfläche ist sehr intuitiv und macht es den Kunden leicht, das zu finden, was sie suchen, und schnell zu bestellen. Das Kundenservice-Team ist immer erreichbar, wenn wir eine Frage haben. Wir können dieses Online-Bestellsystem sehr empfehlen!\"",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Zur Webseite',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'Wie viel sparst du mit Easyresto?',
    provision: 'Portalprovision',
    ordersPerMonth: 'Bestellungen pro Monat',
    orderValue: 'Bestellwert',
    result: 'Ersparnis:',
    perMonth: 'monatlich'
  },
  features: [
    {
      slogan: '100 % mehr Sichtbarkeit',
      title: 'Höhere Platzierungen bei Google im Vergleich zu anderen Anbietern ',
      description: 'Höheres Ranking bei Google im Vergleich zu anderen Anbietern\n' +
        ' Wir nutzen immer die neueste Technologie, um sicherzustellen, dass deine Website immer mit den aktuellsten SEO-Strategien ausgestattet ist. So stellen wir sicher, dass deine Website immer besser platziert ist als bei anderern Anbietern. Wir halten ständig mit den sich ändernden Google Algorithmen Schritt.\n',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'Die Kundenzufriedenheit sinkt aufgrund einer Verzögerung von einer Sekunde um 16%.',
      title: 'Lädt blitzschnell auf dem Handy',
      description: 'Unser System ist so konzipiert, dass es im Vergleich zu anderen Anbietern durchschnittlich bis zu 30% schnellere Ladezeiten bietet. Schneller ladende Websites bringen viele Vorteile mit sich, z. B. eine höhere Kundenzufriedenheit, mehr Konversionen und eine bessere Platzierung in Suchmaschinen. ',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: '88 % der Verbraucher kehren mit geringerer Wahrscheinlichkeit zu einer Website mit schlechter Benutzererfahrung zurück.',
      title: 'Intuitive Benutzerführung',
      description: 'Easyresto ist nicht nur schneller als andere Webshop-Systeme, sondern bietet auch eine intuitive und benutzerfreundliche Oberfläche, die das Bestellen einfach und effizient macht. Mit nur wenigen Klicks können die Kunden die gewünschten Artikel schnell und einfach bestellen. Im Gegensatz zu einigen anderen Anbietern müssen sie sich nicht durch komplizierte Menüs und Bestellvorgänge klicken.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Einfach bestellen - Easyresto!',
    description: 'Verpassen Sie nicht die Gelegenheit, Ihr Restaurant auf die nächste Stufe zu heben - melden Sie sich jetzt bei Easyresto an und steigern Sie Ihre Gewinne!',
    action: {
      label: 'Jetzt anfangen',
    },
    features: [
      'WhatsApp- und E-Mail-Support',
      'Kostenloses Onboarding',
      'Beginnen in weniger als 48 Stunden',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Zugriff von überall und jederzeit',
      title: 'Verwalte deinen Webshop auf dem Smartphone.',
      description: 'Mit EasyResto kannst du deinen Webshop von deinem Handy aus verwalten, jederzeit und überall. Unser responsives Backend ermöglicht es dir, deinen Webshop mit ein paar einfachen Fingertipps zu verwalten.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Kredit-/Debitkarten, Klarna, Sofort, Paypal, Giropay und vieles mehr',
      title: 'Zahlungsarten',
      description: 'Wir bieten deinen Nutzern die Möglichkeit, mit Kreditkarte, PayPal, Google Pay, Apple Pay und anderen Zahlungsmethoden zu bezahlen. Alle Zahlungsmethoden sind.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Ein-Klick-Export für Ihre Bestellungen',
      title: 'Berichte für Steuerberater',
      description: 'Wir wissen, wie wichtig es für Steuerberater/innen ist, über zuverlässige und genaue Rechnungsdaten zu verfügen. Deshalb nehmen wir ihnen mit unserem umfassenden und benutzerfreundlichen webbasierten System die Mühe, den Überblick über ihre Rechnungen zu behalten. Unser System wurde entwickelt, um Prozesse zu rationalisieren und es Steuerberatern zu erleichtern, die Finanzen ihrer Mandanten zu verwalten.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Pflichtfeld',
    'emailRequired': '* Geben Sie bitte eine gültige E-Mail-Adresse an',
    'thankYou': 'VIELEN DANK',
    'thankYouMessage': 'Wir haben deine Anfrage erhalten und werden dich in Kürze kontaktieren.',
    'error': 'Fehler',
    'errorMessage': 'Versuch es noch einmal',
    'name': 'Name*',
    'restaurantName': 'Name des Restaurant*',
    'mobile': 'Mobil*',
    'email': 'Email*',
    'message':'Deine Nachricht',
    'zipCode': 'Postleitzahl*',
    'plan': 'Plan*',
    'monthly': 'Monatlich - 69,00 €',
    'monthlyDescription': '* jederzeit kündbar',
    'yearly': 'Jährlich - 59,00 €',
    'yearlyDescription': '* 12 Monate Vertrag',
    'oneTimeFee': 'Einmalige Gebühr [Gerät bestellen + Marketingmaterial]',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* nur einmal bezahlt',
    'oneTimeFeeInInstallment': '25,00 € / Monat',
    'oneTimeFeeInInstallmentDescription': '* Rate für die ersten 12 Monate',
    'agbConfirm': <>Ich stimme der <a href="/privacy" style={{textDecoration: 'underline'}} target="_blank">Datenschutzerklärung</a> zu.</>,
    'submit': 'Kontaktiere uns',
    'ok': 'Ok',
  },
})
