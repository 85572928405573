import {createTheme} from 'baseui';

const primitives = {
  primary: '#df103f',
  primary50: '#fbe2e8',
  primary100: '#f5b7c5',
  primary200: '#ef889f',
  primary300: '#e95879',
  primary400: '#e4345c',
  primary500: '#df103f',
  primary600: '#db0e39',
  primary700: '#d70c31',
  accent: '#10DF48',
  accent50: '#e2fbe9',
  accent100: '#b7f5c8',
  accent200: '#88efa4',
  accent300: '#58e97f',
  accent400: '#34e463',
  accent500: '#10df48',
  accent600: '#0edb41',
  accent700: '#0cd738',
};

const overrides = {
  colors: {
    buttonPrimaryText: '#fff',
    buttonSecondaryFill: '#36cc52',
    buttonSecondaryText: '#fff',
    buttonSecondaryHover: '#36cc52c4',
    buttonSecondaryActive: '#25b53e',
    buttonTertiaryFill: '#EEEEEE',
    buttonTertiaryText: '#000000',
    buttonTertiaryHover: '#E2E2E2',
    buttonTertiaryActive: '#CBCBCB',
    buttonMinimalFill: 'transparent',
    buttonMinimalText: primitives.primary,
    buttonMinimalHover: primitives.primary300,
    buttonMinimalHoverText: '#fff',
    buttonMinimalActive: primitives.primary700,
  }
}


export const theme = createTheme(primitives, overrides);
