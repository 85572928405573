import React from 'react';
export default (images, pageAction) => ({
  title: 'Restaurant Store',
  navbarMenuItems: [
    {label: 'Start Now'},
    {label: 'Choose your language:'}
  ],
  banner: {
    subTitle: 'Simple, individual & commission-free',
    title: 'Your own online restaurant store',
    description: 'Deliver an intuitive online ordering experience. Reach more customers and make more sales.',
    actionLabel: 'Start Now',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'Our customers are satisfied',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto has been a game-changer for my business. The system is incredibly easy to use, and it lets customers order directly from my website with just a few clicks. The process is extremely smooth and fast, and I'm delighted to say that it has significantly boosted my sales. I highly recommend Easyresto to all restaurant owners who are looking for an easy, efficient way to take orders online.",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Visit website',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "We've been using easyresto for our restaurant's online ordering system for several months now and are absolutely thrilled with the results! It's so easy to use and our customers love the convenience. It's helped increase our sales and our customers are so happy with the process. Highly recommend easyresto for any restaurant looking to offer their customers the best online ordering experience!",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Visit website',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "At Pizz & Via, we couldn't be happier with easyresto. The setup was fast and easy, providing our customers with a premium online ordering experience. Their customer service team is always ready to help and their guidance is top-notch. We highly recommend easyresto for any restaurant looking for an efficient and reliable online ordering system!",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Visit website',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto is a fantastic online ordering system for our African restaurant, Blaues Zebra. We've been using it for a few months now and its streamlined process makes it so easy for customers to order our delicious dishes. The user interface is very intuitive, making it simple for customers to find what they are looking for and place orders quickly. The customer service team is always available any time we have a question. We highly recommend this online ordering system!",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Visit website',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'How much do you save with Easyresto?',
    provision: 'Portal commission',
    ordersPerMonth: 'orders per month',
    orderValue: 'order value',
    result: 'Result:',
    perMonth: 'Per month'
  },
  features: [
    {
      slogan: '100% more visibility',
      title: 'Ranks higher on google compared to other providers',
      description: 'Our system is designed to meet the best practices of today’s search engine optimization standards. We use the latest technology to make sure that your website is always up-to-date with the most current SEO strategies. This helps to ensure that your website is always ranking higher than other providers. We also make sure to keep up with the ever-changing algorithms of the search engines.',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'Customer satisfaction drops by 16% due to a one-second delay.',
      title: 'Loads with lightning speed on mobile',
      description: 'Our system is engineered to provide up to 30% faster loading times on average compared to other providers. Faster loading websites can result in many benefits, such as improved customer satisfaction, increased conversions, and better search engine rankings.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: '88% of consumers are less likely to return to a site with bad user experience.',
      title: 'Intuitive user experience',
      description: 'Not only is easyresto faster than other webshop systems, but it also provides an intuitive and user-friendly interface that makes ordering easy and efficient. With just a few clicks, customers can order the items they need quickly and easily. Unlike some other providers, they don\'t have to go through complicated menus and ordering processes.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Order with Ease - Easyresto!',
    description: 'Don\'t miss out on the opportunity to take your restaurant to the next level - sign up now with Easyresto and start boosting your profits!',
    action: {
      label: 'Start Now',
    },
    features: [
      'Whatsapp & Email Support',
      'Free Onboarding',
      'Start in less than 48hrs',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Access from everywhere, anytime',
      title: 'Manange your webshop on your phone. Backend responsive',
      description: 'If you\'re a restaurant owner, you know how important it is to stay up to date with the latest technology. With EasyResto, you can manage your webshop from your phone, anytime, anywhere. Our responsive backend allows you to easily manage your webshop with a few simple taps.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Credit/Debit Cards, Klarna, Sofort, Paypal, Giropay and much more',
      title: 'Multiple Payment methods',
      description: 'Easyresto webshop system offers users the convenience of choosing their preferred payment' +
        ' method when making purchases. We provide users with the options of credit cards, PayPal, Google Pay, Apple Pay and other payment methods. All payment methods are safe and secure, and all transactions are encrypted.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'One-click export for your orders',
      title: 'Reports for Tax Advisors',
      description: 'We know how important it is for tax advisors to have reliable and accurate invoice accounting information, that\'s why we take the hassle out of keeping track of invoice records with our comprehensive and user-friendly web-based system. Our system is designed to streamline processes, and make it easier for tax advisors to manage their client\'s finances.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Required',
    'emailRequired': '* Please provide a correct email address.',
    'thankYou': 'THANK YOU',
    'thankYouMessage': 'We have received your request and will contact you as soon as possible.',
    'error': 'Error',
    'errorMessage': 'Please try again one more time.',
    'name': 'Name*',
    'restaurantName': 'Restaurant name*',
    'mobile': 'Mobile*',
    'email': 'Email*',
    'zipCode': 'Zip Code*',
    'plan': 'Your Plan*',
    'monthly': 'Monthly - 69,00 €',
    'monthlyDescription': '* cancel anytime',
    'yearly': 'Yearly - 59,00 €',
    'yearlyDescription': '* 12 months contract',
    'oneTimeFee': 'One time fee [ordering device + marketing materials]',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* only paid once',
    'oneTimeFeeInInstallment': '25,00 € / month',
    'oneTimeFeeInInstallmentDescription': '* installment for the first 12 months',
    'agbConfirm': <>I agree to <a href="/agb" target="_blank">Terms & Conditions and Privacy Policy</a>.</>,
    'submit': 'Contact us',
    'ok': 'Ok',
  },
});