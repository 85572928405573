import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import {Button} from 'baseui/button';
import ContactForm from "../containers/SaasMinimal2/ContactForm";
import {loadLangFromLocalStorage} from "./language-modal";
import getData from "../data/restaurant-webshop";

const getPageDirection = () => {
  const lang = loadLangFromLocalStorage() || 'de';
  return getData(lang, 'direction');
}

const ContactModal = ({label, size = 'large'}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return <>
    <Button size={size} kind='primary' onClick={() => setIsOpen(true)}>
      {label}
    </Button>
    <Modal
      onClose={(v) => {
        if (v.closeSource === 'closeButton') {
          setIsOpen(false);
        }
      }}
      overrides={{
        Root: {
          style: {
            zIndex: 9999,
            direction: getPageDirection() ? 'rtl' : 'ltr',
          }
        },
      }}
      closeable
      isOpen={isOpen}
      animate
      focusLock={true}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{label}</ModalHeader>
      <ModalBody>
        <ContactForm onSubmitSuccess={() => setIsOpen(false)}/>
      </ModalBody>
    </Modal>
  </>
}

export default ContactModal;