import styled from 'styled-components';

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid #eee;
  padding: 0 116px;
  background: #fff;
  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;