import React from 'react';
export default (images, pageAction) => ({
  title: 'Boutique du restaurant',
  navbarMenuItems: [
    {label: 'Commencez maintenant'},
    {label: 'Choisissez votre langue :'}
  ],
  banner: {
    subTitle: 'Simple, individuel et sans commission',
    title: 'Votre propre boutique de restauration en ligne',
    description: 'Offrez une expérience de commande en ligne intuitive. Atteignez plus de clients et réalisez plus de ventes.',
    actionLabel: 'Commencez maintenant',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'Nos clients sont satisfaits',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto a changé la donne pour mon entreprise. Le système est incroyablement facile à utiliser et permet aux clients de commander directement sur mon site Web en quelques clics. Le processus est extrêmement fluide et rapide, et je suis ravi de dire qu'il a considérablement augmenté mes ventes. Je recommande vivement Easyresto à tous les restaurateurs qui cherchent un moyen simple et efficace de prendre des commandes en ligne",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Visitez le site web',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "Nous utilisons easyresto pour le système de commande en ligne de notre restaurant depuis plusieurs mois maintenant et nous sommes absolument ravis des résultats ! C'est tellement facile à utiliser et nos clients adorent ce côté pratique. Il a permis d'augmenter nos ventes et nos clients sont très satisfaits du processus. Je recommande vivement easyresto à tous les restaurants qui souhaitent offrir à leurs clients la meilleure expérience de commande en ligne !",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Visitez le site web',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "Chez Pizz & Via, nous ne pourrions pas être plus heureux avec easyresto. L'installation a été rapide et facile, offrant à nos clients une expérience de commande en ligne de premier ordre. L'équipe du service clientèle est toujours prête à aider et ses conseils sont de premier ordre. Nous recommandons vivement easyresto à tout restaurant à la recherche d'un système de commande en ligne efficace et fiable !",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Visitez le site web',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto est un système de commande en ligne fantastique pour notre restaurant africain, Blaues Zebra. Nous l'utilisons depuis quelques mois maintenant et son processus simplifié permet aux clients de commander très facilement nos délicieux plats. L'interface utilisateur est très intuitive, ce qui permet aux clients de trouver facilement ce qu'ils recherchent et de passer rapidement commande. L'équipe du service clientèle est toujours disponible lorsque nous avons une question. Nous recommandons vivement ce système de commande en ligne",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Visitez le site web',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'Combien économisez-vous avec Easyresto ?',
    provision: 'Commission du portail',
    ordersPerMonth: 'commandes par mois',
    orderValue: 'valeur de la commande',
    result: 'Résultat:',
    perMonth: 'Par mois'
  },
  features: [
    {
      slogan: '100% de visibilité en plus',
      title: 'Meilleur classement sur Google par rapport aux autres fournisseurs',
      description: 'Notre système est conçu pour répondre aux meilleures pratiques des normes actuelles d\'optimisation des moteurs de recherche. Nous utilisons les dernières technologies pour nous assurer que votre site Web est toujours à jour avec les stratégies de référencement les plus actuelles. Cela permet de s\'assurer que votre site Web est toujours mieux classé que les autres fournisseurs. Nous veillons également à suivre l\'évolution constante des algorithmes des moteurs de recherche.',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'La satisfaction des clients chute de 16 % en raison d\'un retard d\'une seconde.',
      title: 'Se charge à la vitesse de l\'éclair sur les mobiles',
      description: 'Notre système est conçu pour offrir des temps de chargement jusqu\'à 30 % plus rapides en moyenne par rapport aux autres fournisseurs. Des sites Web qui se chargent plus rapidement peuvent présenter de nombreux avantages, tels qu\'une meilleure satisfaction des clients, une augmentation des conversions et un meilleur classement dans les moteurs de recherche.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: '88 % des consommateurs sont moins susceptibles de retourner sur un site dont l\'expérience utilisateur est mauvaise. ',
      title: 'Une expérience utilisateur intuitive',
      description: 'Non seulement easyresto est plus rapide que les autres systèmes de boutique en ligne, mais il offre également une interface intuitive et conviviale qui rend la commande facile et efficace. En quelques clics, les clients peuvent commander les articles dont ils ont besoin rapidement et facilement. Contrairement à d\'autres fournisseurs, ils n\'ont pas à passer par des menus et des processus de commande compliqués.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Commandez en toute simplicité - Easyresto !',
    description: 'Ne ratez pas l\'occasion de faire passer votre restaurant au niveau supérieur - inscrivez-vous dès maintenant à Easyresto et commencez à augmenter vos bénéfices !',
    action: {
      label: 'Commencez maintenant',
    },
    features: [
      'Assistance par téléphone et par courriel',
      'Embarquement gratuit',
      'Début en moins de 48 heures',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Accès de partout, à tout moment',
      title: 'Gérez votre boutique en ligne depuis votre téléphone. Backend responsive',
      description: 'Si vous êtes propriétaire d\'un restaurant, vous savez combien il est important de rester à la pointe de la technologie. Avec EasyResto, vous pouvez gérer votre boutique en ligne depuis votre téléphone, à tout moment et en tout lieu. Notre backend réactif vous permet de gérer facilement votre boutique en ligne en quelques clics.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Cartes de crédit/débit, Klarna, Sofort, Paypal, Giropay et bien d\'autres encore.',
      title: 'Méthodes de paiement multiples',
      description: 'Le système de boutique en ligne Easyresto offre aux utilisateurs la possibilité de choisir leur mode de paiement préféré lorsqu\'ils effectuent des achats. Nous offrons aux utilisateurs les options de cartes de crédit, PayPal, Google Pay, Apple Pay et d\'autres méthodes de paiement. Toutes les méthodes de paiement sont sûres et sécurisées, et toutes les transactions sont cryptées.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Exportation en un clic de vos commandes',
      title: 'Rapports pour les conseillers fiscaux',
      description: 'Nous savons à quel point il est important pour les conseillers fiscaux de disposer d\'informations fiables et précises sur la comptabilité des factures, c\'est pourquoi nous leur épargnons les tracas du suivi des factures grâce à notre système en ligne complet et convivial. Notre système est conçu pour rationaliser les processus et faciliter la gestion des finances des clients par les conseillers fiscaux.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Requis',
    'emailRequired': '* Veuillez fournir une adresse électronique correcte.',
    'thankYou': 'MERCI',
    'thankYouMessage': 'Nous avons reçu votre demande et nous vous contacterons dès que possible.',
    'error': 'Erreur',
    'errorMessage': 'Veuillez essayer encore une fois.',
    'name': 'Nom*',
    'restaurantName': 'Nom du restaurant*',
    'mobile': 'Mobile*',
    'email': 'Courriel*',
    'zipCode': 'Code postal*',
    'plan': 'Votre plan*',
    'monthly': 'Mensuel - 69,00 €',
    'monthlyDescription': '* annuler à tout moment',
    'yearly': 'Annuel - 59,00 €',
    'yearlyDescription': '* Contrat de 12 mois',
    'oneTimeFee': 'Frais uniques [dispositif de commande + matériel de marketing].',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* payé une seule fois',
    'oneTimeFeeInInstallment': '25,00 € / mois',
    'oneTimeFeeInInstallmentDescription': '* versement pour les 12 premiers mois',
    'agbConfirm': <>Je suis d'accord pour <a href="/agb" target="_blank">Conditions générales et politique de confidentialité</a>.</>,
    'submit': 'Nous contacter',
    'ok': 'Ok',
  },
});