import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import {Button} from 'baseui/button';
import { Icon } from 'react-icons-kit'
import {ic_language} from 'react-icons-kit/md/ic_language';
import getData from "../data/restaurant-webshop";



const saveLangToLocalStorage = (lang) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('CURRENT_LANGUAGE', lang);
  }
}

export const loadLangFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('CURRENT_LANGUAGE');
  }
}

const getPageDirection = () => {
  const lang = loadLangFromLocalStorage() || 'de';
  return getData(lang, 'direction');
}

const LanguageModal = ({label}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentLang, setCurrentLang] = React.useState('de');

  const languages = [
    {label: 'English', value: 'en'},
    {label: 'Deutsch', value: 'de'},
    {label: 'Türkçe', value: 'tr'},
    {label: 'Tiếng Việt', value: 'vi'},
    {label: 'Español', value: 'es'},
    {label: 'Français', value: 'fr'},
    {label: 'العربية', value: 'ar'},
  ];

  const loadCurrentLanguage = () => {
    const lang = loadLangFromLocalStorage();
    if (lang === null) {
      setCurrentLang('de');
      saveLangToLocalStorage('de');
      setIsOpen(true);
    } else {
      setCurrentLang(lang);
    }
  }

  React.useEffect(() => {
    loadCurrentLanguage();
  }, []);

  return <>
    <Button kind="minimal" size="compact" onClick={() => setIsOpen(true)}>
      <Icon icon={ic_language} size={24}/>
    </Button>
    <Modal
      onClose={() => {
        setIsOpen(false);
      }}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      overrides={{
        Root: {
          style: {
            direction: getPageDirection() ? 'rtl' : 'ltr',
          }
        },
      }}
      size={SIZE.default}
      role={ROLE.dialog}>
      <ModalHeader>{label}</ModalHeader>
      <ModalBody>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          {
            languages.map((l) => <ModalButton
              key={l.value} kind={l.value === currentLang ? 'primary' : 'tertiary'}
              onClick={() => {
                saveLangToLocalStorage(l.value);
                window.document.location.reload();
                setCurrentLang(l.value);
                setIsOpen(false);
              }}
              style={{width: '100%'}}>{l.label}</ModalButton>)
          }
        </div>
      </ModalBody>
    </Modal>
  </>
}

export default LanguageModal;