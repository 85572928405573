import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import {Link} from 'gatsby';

export default () => {
  let localStorage = {
    getItem: () => {},
    setItem: () => {},
  }

  if(typeof window !== 'undefined') {
    localStorage = window.localStorage;
  }
  const [isOpen, setIsOpen] = React.useState(localStorage.getItem('agreed') !== '1');

  function acceptCookies() {
    setIsOpen(false);
    localStorage.setItem('agreed', '1');
  }

  return (
    <Modal isOpen={isOpen} closeable={false} overrides={{
      Root: {
        style: {zIndex: 9999}
      }
    }}>
      <ModalHeader>Einwilligung in die Cookie Nutzung</ModalHeader>
      <ModalBody>
        Wir verwenden Cookies und andere Technologien, z. B. um Ihnen personalisierte Inhalte und Werbung
        anzuzeigen. Weitere Informationen und
        Ihre persönlichen Tracking-Einstellungen finden Sie in unserer <Link to="/agb">Datenschutzerklärung</Link>.
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={acceptCookies}>Akzeptieren</ModalButton>
      </ModalFooter>
    </Modal>
  );
}