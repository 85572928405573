import React from 'react';
export default (images, pageAction) => ({
  direction: 'RTL',
  title: 'متجر ويب لمطعمك',
  navbarMenuItems: [
    {label: 'ابدأ الآن'},
    {label: 'اختر لغتك:'}
  ],
  banner: {
    subTitle: 'بسيطة وفردية وبدون اي عمولة',
    title: 'متجر المطعم الخاص بك على الإنترنت',
    description: 'قدِّم تجربة طلب شراء عبر الإنترنت سهلة الاستخدام. الوصول إلى المزيد من العملاء وتحقيق المزيد من المبيعات.',
    actionLabel: 'ابدأ الآن',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'عملاؤنا راضون عنا',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "لقد غيرت Easyresto قواعد اللعبة في عملي. النظام سهل الاستخدام بشكل لا يصدق ، ويتيح للعملاء الطلب مباشرة من موقع الويب الخاص بي ببضع نقرات. العملية سلسة وسريعة للغاية ، ويسعدني أن أقول إنها عززت مبيعاتي بشكل كبير. أوصي بشدة باستخدام Easyresto لجميع أصحاب المطاعم الذين يبحثون عن طريقة سهلة وفعالة لتلقي الطلبات عبر الإنترنت.",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'زيارة الموقع',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "لقد استخدمنا easyresto لنظام الطلبات عبر الإنترنت في مطعمنا لعدة أشهر حتى الآن ونحن سعداء للغاية بالنتائج! إنه سهل الاستخدام للغاية ويحب عملاؤنا الراحة. لقد ساعد في زيادة مبيعاتنا وعملائنا سعداء جدًا بهذه العملية. نوصي بشدة باستخدام easyresto لأي مطعم يتطلع إلى تقديم أفضل تجربة طلب عبر الإنترنت لعملائه!",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'زيارة الموقع',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "في Pizz & Via ، لا يمكننا أن نكون أكثر سعادة مع easyresto. كان الإعداد سريعًا وسهلاً ، مما وفر لعملائنا تجربة شراء متميزة عبر الإنترنت. فريق خدمة العملاء جاهز دائمًا للمساعدة وتوجيهاتهم من الدرجة الأولى. نوصي بشدة باستخدام easyresto لأي مطعم يبحث عن نظام طلب إلكتروني فعال وموثوق به!",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'زيارة الموقع',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto هو نظام رائع للطلبات عبر الإنترنت لمطعمنا الأفريقي Blaues Zebra. لقد استخدمناه منذ بضعة أشهر حتى الآن وتسهل عمليته المبسطة على العملاء طلب أطباقنا اللذيذة. واجهة المستخدم بديهية للغاية ، مما يجعل من السهل على العملاء العثور على ما يبحثون عنه وتقديم الطلبات بسرعة. فريق خدمة العملاء متاح دائمًا في أي وقت لدينا سؤال. نحن نوصي بشدة بنظام الطلب عبر الإنترنت هذا!",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'زيارة الموقع',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'كم توفر مع Easyresto؟',
    provision: 'العمولة التي تدفعها',
    ordersPerMonth: 'طلب في الشهر',
    orderValue: 'متوسط قيمة الطلب',
    result: 'النتيجه:',
    perMonth: 'شهريا'
  },
  features: [
    {
      slogan: '100٪ رؤية أكثر',
      title: 'مرتبة أعلى على جوجل مقارنة بمقدمي الخدمة الآخرين',
      description: 'تم تصميم نظامنا لتلبية أفضل الممارسات لمعايير تحسين محركات البحث الحالية. نحن نستخدم أحدث التقنيات للتأكد من أن موقع الويب الخاص بك محدث دائمًا بأحدث استراتيجيات تحسين محركات البحث. يساعد هذا في ضمان أن يكون موقع الويب الخاص بك دائمًا في مرتبة أعلى من مقدمي الخدمات الآخرين. نتأكد أيضًا من مواكبة الخوارزميات المتغيرة باستمرار لمحركات البحث.',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'ينخفض رضا العملاء بنسبة 16٪ بسبب التأخير لمدة ثانية واحدة.',
      title: 'تحميل بسرعة البرق على الهاتف المحمول',
      description: 'تم تصميم نظامنا لتوفير أوقات تحميل أسرع بنسبة تصل إلى 30٪ في المتوسط مقارنة بمقدمي الخدمة الآخرين. يمكن أن تؤدي مواقع الويب التي يتم تحميلها بشكل أسرع إلى العديد من الفوائد ، مثل تحسين رضا العملاء وزيادة التحويلات وتصنيفات محركات البحث الأفضل.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'من غير المرجح أن يعود 88٪ من المستهلكين إلى موقع ذي تجربة مستخدم سيئة.',
      title: 'تجربة مستخدم بديهية',
      description: 'ليس easyresto أسرع من أنظمة متجر الويب الأخرى فحسب ، بل إنه يوفر أيضًا واجهة سهلة الاستخدام وسهلة الاستخدام تجعل الطلب سهلاً وفعالًا. بنقرات قليلة فقط ، يمكن للعملاء طلب العناصر التي يحتاجونها بسرعة وسهولة. على عكس بعض مقدمي الخدمة الآخرين ، لا يتعين عليهم المرور بقوائم معقدة وعمليات ترتيب.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'اطلب بسهولة - Easyresto!',
    description: 'لا تفوت فرصة الانتقال بمطعمك إلى المستوى التالي - اشترك الآن مع Easyresto وابدأ في زيادة أرباحك!',
    action: {
      label: 'ابدأ الآن',
    },
    features: [
      'دعم Whatsapp والبريد الإلكتروني',
      'إعداد مجاني لمطعمك',
      'ابدأ في أقل من 48 ساعة',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'الوصول من أي مكان وفي أي وقت',
      title: 'إدارة متجر الويب الخاص بك على هاتفك',
      description: 'إذا كنت مالك مطعم ، فأنت تعلم مدى أهمية مواكبة أحدث التقنيات. مع EasyResto ، يمكنك إدارة متجرك الإلكتروني من هاتفك في أي وقت وفي أي مكان. تتيح لك الواجهة الخلفية سريعة الاستجابة إدارة متجرك الإلكتروني بسهولة ببضع نقرات بسيطة.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'بطاقات الائتمان / الخصم ، Klarna ، Sofort ، Paypal ، Giropay وأكثر من ذلك بكثير',
      title: 'طرق دفع متعددة',
      description: 'يوفر نظام متجر الويب Easyresto للمستخدمين سهولة اختيار طريقة الدفع المفضلة لديهم عند الشراء. نوفر للمستخدمين خيارات بطاقات الائتمان و PayPal و Google Pay و Apple Pay وطرق الدفع الأخرى. جميع طرق الدفع آمنة ومأمونة ، وجميع المعاملات مشفرة.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'بنقرة واحدة تصدير لطلبات مطعمك',
      title: 'تقارير لمستشاري الضرائب',
      description: 'نحن نعلم مدى أهمية حصول مستشاري الضرائب على معلومات موثوقة ودقيقة حول محاسبة الفواتير ، ولهذا السبب نتخلص من متاعب تتبع سجلات الفواتير من خلال نظامنا الشامل وسهل الاستخدام المستند إلى الويب. تم تصميم نظامنا لتبسيط العمليات ، وتسهيل إدارة الشؤون المالية لعملائهم على مستشاري الضرائب.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* مطلوب',
    'emailRequired': '* يرجى تقديم عنوان بريد إلكتروني صحيح.',
    'thankYou': 'شكرا لك',
    'thankYouMessage': 'لقد تلقينا طلبك وسنتصل بك في أقرب وقت ممكن.',
    'error': 'خطأ',
    'errorMessage': 'يرجى المحاولة مرة أخرى.',
    'name': 'اسمك*',
    'restaurantName': 'اسم المطعم *',
    'mobile': 'التليفون المحمول*',
    'email': 'البريد الإلكتروني*',
    'zipCode': 'الرمز البريدي للمطعم *',
    'plan': 'خطتك المفضلة *',
    'monthly': 'شهريا - 69,00 €',
    'monthlyDescription': '* الإلغاء في أي وقت',
    'yearly': 'سنوي - 59,00 €',
    'yearlyDescription': '* عقد لمدة 12 شهر',
    'oneTimeFee': 'رسم لمرة واحدة [جهاز طلب + مواد تسويقية]',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* تدفع مرة واحدة فقط',
    'oneTimeFeeInInstallment': '25,00 € /  شهر',
    'oneTimeFeeInInstallmentDescription': '* التقسيط على أول 12 شهر',
    'agbConfirm': <>أوافق على <a href="/agb" target="_blank">الشروط والأحكام وسياسة الخصوصية</a>.</>,
    'submit': 'اتصل بنا',
    'ok': 'موافق',
  },
});