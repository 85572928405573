import {graphql, useStaticQuery} from "gatsby";
import ENGLISH_TRANSLATIONS from './en';
import GERMAN_TRANSLATIONS from './de';
import TURKISH_TRANSLATIONS from './tr';
import VITNAM_TRANSLATIONS from './vi';
import SPANISH_TRANSLATIONS from './es';
import FRENCH_TRANSLATIONS from './fr';
import ARABIC_TRANSLATIONS from './ar';


export default (lang = 'en', key = null) => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "image/landing-page/hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      googlerank: file(
        relativePath: { eq: "image/landing-page/google-rank.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      pagespeed: file(
        relativePath: { eq: "image/landing-page/pagespeed.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      ux: file(
        relativePath: { eq: "image/landing-page/user-experience.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      responsive: file(
        relativePath: { eq: "image/landing-page/responsive.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      payments: file(
        relativePath: { eq: "image/landing-page/payment-methods.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      tax: file(
        relativePath: { eq: "image/landing-page/tax-export.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 703
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const pageAction = {
    link: '/click-here',
  }
  const data = {
    'en': ENGLISH_TRANSLATIONS(images, pageAction),
    'de': GERMAN_TRANSLATIONS(images, pageAction),
    'tr': TURKISH_TRANSLATIONS(images, pageAction),
    'vi': VITNAM_TRANSLATIONS(images, pageAction),
    'es': SPANISH_TRANSLATIONS(images, pageAction),
    'fr': FRENCH_TRANSLATIONS(images, pageAction),
    'ar': ARABIC_TRANSLATIONS(images, pageAction),
  };
  const languageData = lang in data ? data[lang] : data['de'];
  if (key)
    return languageData[key];
  return languageData;
};